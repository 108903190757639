// oktaAuth.js
import { OktaAuth } from "@okta/okta-auth-js"
import config from "./config"
import workforceConfig from "./workforceConfig"

const randomState = () => Math.random().toString(36).slice(2)
let state = window.sessionStorage.getItem("login_state_reference")
let ssoApplication = window.sessionStorage.getItem("sso_application")
const urlParams = new URLSearchParams(window.location.search)
ssoApplication = ssoApplication ?? urlParams.get("sso_application")

if (ssoApplication) {
  window.sessionStorage.setItem("sso_application", ssoApplication)
}

const userIsWorkforce = ssoApplication === "workforce"
const currentConfig = userIsWorkforce ? workforceConfig : config

if (!state) {
  state = randomState()
  window.sessionStorage.setItem("login_state_reference", state)
}

const oktaAuth = new OktaAuth({ state, ...currentConfig })

export default oktaAuth
