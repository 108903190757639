// See BSMG-3163
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type RouteLocation, createRouter, createWebHistory } from "vue-router"
import { LoginCallback, navigationGuard } from "@okta/okta-vue"
import {
  getOpenFeatureClient,
  addFeatureFlagListener,
  waitForClientProviderReady,
} from "@justworkshr/frontend-launch-darkly-open-feature-integration"
import { setupLayouts } from "virtual:generated-layouts"
import generatedRoutes from "virtual:generated-pages"
import { useGlobalError } from "stella"
import { getViewer, loaded, isEmployeeRestricted } from "@/viewer"
import AuthTest from "@/components/AuthTest.vue"

declare module "vue-router" {
  // must be declared by every route
  interface RouteMeta {
    requiresAuth: boolean
    featureFlag?: string
    abilities?: string[]
  }
}

// Vite URL injected via secrets in GitHub actions
const PAYROLL_PROVIDER_LOGIN = `${import.meta.env.VITE_PAYROLL_PROVIDER_URL}`

// TODO - this state is not global. Must revise useGlobalError and useApiFetch states
const { resetGlobalError } = useGlobalError(PAYROLL_PROVIDER_LOGIN)

const hasQueryParams = (route: RouteLocation) =>
  !!Object.keys(route.query).length

const noSageSessionCookie = () => !document.cookie.includes("_sage_session_id")

const urlParams = new URLSearchParams(window.location.search)

const newTargetMemberId = urlParams.get("target_member_id")
const currentTargetMemberId = window.sessionStorage.getItem("target_member_id")
const ssoEnabled =
  window.sessionStorage.getItem("sso_auth_enabled") !== "false" &&
  noSageSessionCookie()

const requiresAuth = Boolean(newTargetMemberId || ssoEnabled)

if (newTargetMemberId && newTargetMemberId !== currentTargetMemberId) {
  window.sessionStorage.setItem("target_member_id", newTargetMemberId)
}

window.sessionStorage.setItem("sso_auth_enabled", String(requiresAuth))

const authenticatedRoutes = generatedRoutes.map((route) => {
  route.meta = { requiresAuth, ...route.meta }
  return route
})

const routes = setupLayouts([
  {
    path: "/login/callback",
    component: LoginCallback,
    meta: { requiresAuth: false, noAuth: true },
  },
  ...authenticatedRoutes,
])

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// eslint-disable-next-line @typescript-eslint/unbound-method
const originalPush = router.push

// This is a workaround for an error in vue-router when a navigation guard redirects more than once.
router.push = function push(location) {
  // @ts-expect-error Incompatible types
  return originalPush.call(this, location).catch((err: Error) => {
    const reg = new RegExp(
      '^Redirected when going from "[a-z_.\\/]+" to "[a-z_.\\/]+" via a navigation guard.$'
    )
    if (reg.test(err.message)) {
      return Promise.resolve(false)
    }
    return Promise.reject(err)
  })
}

// Copies query params from the previous route to the next route
router.beforeEach((to, from, next) => {
  if (
    !hasQueryParams(to) &&
    hasQueryParams(from) &&
    // Allow route to conditionally drop query parameters
    !to.meta.clearQueryParams
  ) {
    next({ ...to, query: { ...to.query, ...from.query } })
  } else {
    next()
  }
})

router.beforeEach(navigationGuard)

router.beforeEach(async (to, from, next) => {
  if (to.path == "/login/callback") return next()
  if (!to.meta.noAuth && !loaded.value) {
    await getViewer()
  }

  let isRouteDisabled = false

  if (to.meta.featureFlag) {
    await waitForClientProviderReady()
    isRouteDisabled = !getOpenFeatureClient().getBooleanValue(
      to.meta.featureFlag,
      false
    )
  }

  const routeRequiresAbilities = !!to.meta.abilities
  const isEmployeeRestrictedFromRoute = routeRequiresAbilities
    ? isEmployeeRestricted(to.meta.abilities)
    : false

  if (isRouteDisabled || isEmployeeRestrictedFromRoute) next(from)
  else next()
})

// Temporary nextben reroute. Will be removed once oldben is deprecated
const oldbenDashboardPages = [
  "benefits/active",
  "benefits/add-benefits",
  "benefits/wellness-perks",
  "admin/benefits/add",
  "admin/benefits/active",
]
router.beforeEach(async (to, _from, next) => {
  if (
    oldbenDashboardPages.find((page) => to.path.includes(page)) &&
    !to.path.includes("nextben")
  ) {
    await waitForClientProviderReady()
    const isNextBen = getOpenFeatureClient().getBooleanValue(
      "benefits-platform-nextben-experience",
      false
    )

    if (isNextBen) {
      const nextBenDashboard = to.path.includes("admin") ? "admin" : "employee"

      next(
        `/nextben/${nextBenDashboard}/benefits${
          to.path.includes("add") ? "/add" : ""
        }`
      )
    } else next()
  } else next()
})

router.afterEach(() => {
  resetGlobalError()
})

// If a feature flag is turned off while someone is on the page, kick them off!
generatedRoutes
  .filter((route) => !!route.meta?.featureFlag)
  .forEach((route) => {
    addFeatureFlagListener(route.meta!.featureFlag!, (isEnabled) => {
      if (router.currentRoute.value.path.startsWith(route.path) && !isEnabled) {
        router.push({ path: "/" }).catch(console.error)
      }
    })
  })

export const loginRedirect = (payrollProviderUrl?: string) => {
  if (payrollProviderUrl) {
    void window.location.assign(`${payrollProviderUrl}`)
  } else {
    void window.location.assign(PAYROLL_PROVIDER_LOGIN)
  }
}

export default router
