export enum BenefitType {
  MEDICAL = "medical",
  DENTAL = "dental",
  VISION = "vision",
  LIFE = "life",
  FSA = "health_care_flexible_spending_account",
  DCFSA = "dependent_care_flexible_spending_account",
  HSA = "health_savings_account",
  COMMUTER = "commuter",
  STD = "std",
  LTD = "ltd",
  RETIREMENT = "401k",
  INTERNATIONAL_EOR_HEALTH_INSURANCE = "international_eor_health_insurance",
  INTERNATIONAL_EOR_DENTAL_INSURANCE = "international_eor_dental_insurance",
  INTERNATIONAL_EOR_LIFE_INSURANCE = "international_eor_life_insurance",
  INTERNATIONAL_EOR_PENSION = "international_eor_pension",
  INTERNATIONAL_EOR_FOOD_VOUCHER = "international_eor_food_voucher",
  INTERNATIONAL_EOR_COMPUTER_LEASING = "international_eor_computer_leasing",
  INTERNATIONAL_EOR_STD = "international_eor_short_term_disability_insurance",
  INTERNATIONAL_EOR_LTD = "international_eor_long_term_disability_insurance",
  CUSTOM = "custom_benefit",
  PELOTON = "peloton",
  CITIBIKE = "citibike",
  KINDBODY = "kindbody",
  ONE_MEDICAL = "one_medical",
  TALKSPACE = "talkspace",
  TELADOC = "teladoc",
  HEALTH_ADVOCATE = "health_advocate",
}

export enum LifeAndLeaveBenefitType {
  LIFE = "life_insurance",
  LTD = "long_term_disability",
  STD = "short_term_disability",
}

export type BenefitTypeValues =
  | "health"
  | "medical"
  | "dental"
  | "vision"
  | "life_accidental_death_dismemberment_insurance"
  | "short_term_disability_insurance"
  | "long_term_disability_insurance"
  | "401k"
  | "custom_benefit"
  | OptumBenefitTypeValues
  | InternationalBenefitTypeValues

export type InternationalBenefitTypeValues =
  | "international_eor_health_insurance"
  | "international_eor_dental_insurance"
  | "international_eor_life_insurance"
  | "international_eor_pension"
  | "international_eor_food_voucher"
  | "international_eor_computer_leasing"
  | "international_eor_short_term_disability_insurance"
  | "international_eor_long_term_disability_insurance"

export type CompanyBenefitStatus = "upcoming" | "active"

export type OptumBenefitTypeValues =
  | "commuter"
  | "health_care_flexible_spending_account"
  | "dependent_care_flexible_spending_account"
  | "health_savings_account"

export enum EnrollmentStatus {
  ENROLLED = "enrolled",
  NOT_ENROLLED = "not_enrolled",
  // TODO - 90% sure I'm mixing up two status enums
  ACTIVE = "active",
  UPCOMING = "upcoming",
  PENDING = "pending",
}

export enum CoverageTier {
  EMPLOYEE = "employee",
  EMPLOYEE_PLUS_SPOUSE = "employee_plus_spouse",
  EMPLOYEE_PLUS_CHILDREN = "employee_plus_children",
  EMPLOYEE_PLUS_FAMILY = "employee_plus_family",
}

export type Deduction = Record<"family" | "individual", string>

export enum PayrollProvider {
  JUSTWORKS_PEO = "justworks_peo",
  JUSTWORKS_PAYROLL = "justworks_payroll",
}

// benefits_registry/app/public/benefits/registry/constants.rb
export enum PlanNetworkType {
  EPO = "epo",
  HMO = "hmo",
  POS = "pos",
  PPO = "ppo",
}

export type ColorType =
  | "blue"
  | "red"
  | "green"
  | "yellow"
  | "purple"
  | "blue-green"
  | "blue-yellow"
  | "green-yellow"
  | "red-purple"
  | "yellow-purple"

export enum QuoteStatusType {
  READY = "ready",
  PENDING = "pending",
  INELIGIBLE = "ineligible",
  ERROR = "error",
}
