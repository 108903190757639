// See BSMG-3163
/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/require-await, @typescript-eslint/no-unsafe-member-access */
const { VITE_OKTA_CLIENT_ID, VITE_OKTA_ISSUER, VITE_OKTA_REDIRECT_URI } =
  import.meta.env

export default {
  clientId: VITE_OKTA_CLIENT_ID,
  issuer: VITE_OKTA_ISSUER,
  redirectUri: VITE_OKTA_REDIRECT_URI,
  scopes: ["openid"],
  pkce: true,
  restoreOriginalUri: async (_oktaAuth, originalUri) => {
    window.location = originalUri.href
  },
}
