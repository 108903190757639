<script setup>
import "@justworkshr/alma/dist/style.css"
import "@justworkshr/display-icons/style.css"
import "@/styles/buttons.css"
import { provide, watch } from "vue"
import { datadogRum } from "@datadog/browser-rum"
import { Button, Notification, Spinner, Stack } from "@justworkshr/alma"
import { updateFeatureFlagContextForEmployeeAndCompany } from "@justworkshr/frontend-launch-darkly-open-feature-integration"
import { useGlobalError } from "stella"
import { PayrollProvider } from "@/types"
import {
  viewer,
  fetching as fetchingViewer,
  error as viewerError,
  loaded as viewerLoaded,
} from "@/viewer"
import { ViewerSymbol } from "./symbols"

const { errorCode, errorMessage, errorHandler, resetGlobalError } =
  // Vite URL injected via secrets in github actions
  useGlobalError(`${import.meta.env.VITE_PAYROLL_PROVIDER_URL}`)

function reload() {
  window.location.reload()
}

provide(ViewerSymbol, viewer)
watch(errorCode, () => {
  errorHandler.value()
})

watch(viewer, () => {
  if (viewer.value) {
    const isPeoCompany =
      viewer.value.member.payroll_provider === PayrollProvider.JUSTWORKS_PEO

    updateFeatureFlagContextForEmployeeAndCompany(
      viewer.value.member.id,
      viewer.value.company.id,
      { company: { isPeoCompany } }
    ).catch(console.error)

    datadogRum.setUser({
      id: viewer.value.member.id,
      companyId: viewer.value.company.id,
    })

    if ("pendo" in window) {
      /* eslint-disable @typescript-eslint/no-unsafe-call */
      window.pendo.initialize({
        visitor: {
          id: viewer.value.member.id,
        },
        account: {
          id: viewer.value.company.id,
        },
      })
      /* eslint-enable @typescript-eslint/no-unsafe-call */
    }
  }
})
</script>

<template>
  <router-view v-slot="{ Component }">
    <div class="page-background">
      <div v-if="!viewerLoaded && fetchingViewer" class="status">
        <Spinner v-if="fetchingViewer" />
      </div>
      <div v-else-if="viewerError" class="status">
        <p>{{ errorMessage || "Hmm. Something’s wrong." }}</p>
        <Button @click="reload">Try again</Button>
      </div>
      <template v-else-if="viewerLoaded || $route.meta?.noAuth">
        <Notification
          v-if="errorMessage"
          :message="errorMessage"
          type="error"
          @dismiss="resetGlobalError()"
        >
          <Stack>
            <span>{{ errorMessage }}</span>
          </Stack>
        </Notification>
        <component :is="Component" />
      </template>
    </div>
  </router-view>
</template>

<style scoped>
html {
  background-color: var(--color-window-background);
}

body {
  user-select: none;
  touch-action: pan-y;
}

p,
td,
dd,
small {
  user-select: text;
}

.page-background {
  background-color: var(--color-page-background);
  height: 100vh;
}

.status {
  height: 100px;
  inset: 0;
  margin: auto;
  max-width: 500px;
  position: absolute;
  text-align: center;
}
</style>
