// See BSMG-3163
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const { VITE_OKTA_WORKFORCE_ISSUER, VITE_OKTA_WORKFORCE_CLIENT_ID } =
  import.meta.env

import config from "./config"

export default {
  ...config,
  clientId: VITE_OKTA_WORKFORCE_CLIENT_ID,
  issuer: VITE_OKTA_WORKFORCE_ISSUER,
}
