import { configure, defineRule } from "vee-validate"
import { required } from "@vee-validate/rules"

defineRule("required", required)

configure({
  generateMessage: (context) => {
    let words = context.field.split(" ")
    switch (context.rule.name) {
      case "required":
        return words.length > 2
          ? "This is required"
          : `${context.field} is required`
    }
  },
})
